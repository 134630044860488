/** @jsx jsx */
import { jsx } from "theme-ui";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Slide from "react-reveal/Slide";
import styled from "@emotion/styled";
import marsTheme from "../gatsby-plugin-theme-ui/marsTheme";
import { MarsLogoWhite, MarsTextLogoWhite } from "../hooks/images";
// Fluid sharp queries, I seperated them so we could query multiple files at once
//! using this query to bring in the stock images
// export const fluidImage = graphql`
//   fragment fluidImagePrivacy on File {
//     childImageSharp {
//       fluid(fit: CONTAIN, quality: 100) {
//         ...GatsbyImageSharpFluid_withWebp
//       }
//     }
//   }
// `;
// export const Data = () => {
//   const privacyPage = useStaticQuery(
//     graphql`
//       query privacyPageQuery {
//         privacyBg: file(relativePath: { eq: "MARS404D_404Desktop.jpg" }) {
//           ...fluidImage
//         }
//       }
//     `
//   );
//   return privacyPage;
// };

// export const PrivacyBG = () => {
//   const image = Data();
//   return (
//     <StyledBackgroundLanding
//       fluid={image.privacyBg.childImageSharp.fluid}
//       alt="Earth Landing Hero"
//     />
//   );
// };

// export const StyledBackgroundLanding = styled(Img)`
//   width: 100%;
//   height: 3500px;
//   object-fit: cover;
//   overflow: hidden;
//   background-position: bottom center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   z-index: 0;
//   position: absolute !important;
//   @media (max-width: 1200px) {
//     height: 5000px;
//   }
//   @media (max-width: 900px) {
//     height: 4500px;
//   }
//   @media (max-width: 500px) {
//     height: 5000px;
//   }
//   @media (min-width: 1200px) {
//     height: 5200px;
//   }
// `;
export const StyledText = styled.p``;

// TODO COmplete 404page
const Privacy = () => {
  return (
    <div>
      {/* <PrivacyBG /> */}
      <div
        sx={{
          position: `relative`,
          backgroundColor: marsTheme.colors.black,
        }}
      >
        <header>
          <nav>
            <ul
              sx={{
                position: `relative`,
                display: `flex`,
                justifyContent: `space-between`,
                width: `full`,
                listStyle: `none`,
                paddingTop: [3, 3, 4, 4],
                mt: 0,
                zIndex: `50`,
                alignItems: `center`,
              }}
            >
              <Slide left delay={2000}>
                <li
                  sx={{
                    width: [`9rem`, `9rem`, `10rem`, `11rem`],
                    pl: [3, 3, 4, 4],
                  }}
                >
                  <MarsTextLogoWhite />
                </li>
              </Slide>
              <Slide right delay={2000}>
                <li
                  sx={{
                    width: [`3rem`, `3rem`, `5rem `, `6rem`],
                    pr: [3, 3, 4, 4],
                    transition: `2s ease`,
                  }}
                >
                  <Link to="/">
                    <MarsLogoWhite />
                  </Link>
                </li>
              </Slide>
            </ul>
          </nav>
        </header>
        <center>
          <div
            sx={{
              marginTop: 5,
              h2: {
                fontSize: [2, 3, 4, 4, 6],
                fontFamily: `Libre Baskerville`,
                color: marsTheme.colors.marsRed,
                m: 0,
                marginTop: [3, 2, 3, 4, 5],
                lineHeight: 1.2,
              },
              p: {
                fontSize: [0, 1, 2, 3, 3],
                fontFamily: `Libre Baskerville`,
                color: marsTheme.colors.clay,
                width: [`90%`, null, null, `85%`, `3/4`],
              },
            }}
          >
            <h1
              sx={{
                fontSize: [5, 5, 7, 7, `6rem`],
                fontFamily: `Restora`,
                color: marsTheme.colors.clay,
                margintop: [3, 4, 5, 0],
                lineHeight: `1`,
              }}
            >
              Privacy Policy
            </h1>
            <h2>Introduction</h2>
            <p>
              Protecting your private information is our priority. This
              Statement of Privacy applies to
              <a
                target="_blank"
                referrerPolicy="strict-origin-when-cross-origin"
                rel="noreferrer"
                href=" www.themarscontract.com"
              >
                {" "}
                www.themarscontract.com
              </a>{" "}
              and The Mars Contract and governs data collection and usage. For
              the purposes of this Privacy Policy, unless otherwise noted, all
              references to The Mars Contract include{" "}
              <a
                target="_blank"
                referrerPolicy="strict-origin-when-cross-origin"
                rel="noreferrer"
                href=" www.themarscontract.com"
              >
                {" "}
                www.themarscontract.com
              </a>{" "}
              and TMC. The Mars Contract website is a consulting and power
              coaching site. By using the TMC website, you consent to the data
              practices described in this statement.
            </p>
            <h2>Collection of your Personal Information</h2>
            <p>
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services available on the Site. These may
              include: (a) registering for an account on our Site; (b) sending
              us an email message; (c) submitting your credit card or other
              payment information when ordering and purchasing products and
              services on our Site. To wit, we will use your information for,
              but not limited to, communicating with you in relation to services
              and/or products you have requested from us. We also may gather
              additional personal or non-personal information in the future.
            </p>
            <h2>Use of your Personal Information</h2>
            <p>
              TMC collects and uses your personal information to operate its
              website(s) and deliver the services you have requested. TMC may
              also use your personally identifiable information to inform you of
              other products or services available from TMC and its affiliates.
            </p>
            <h2>Sharing Information with Third Parties</h2>
            <p>
              TMC does not sell, rent or lease its customer lists to third
              parties. TMC may share data with trusted partners to help perform
              statistical analysis, send you email or postal mail, provide
              customer support, or arrange for deliveries. All such third
              parties are prohibited from using your personal information except
              to provide these services to TMC, and they are required to
              maintain the confidentiality of your information. TMC may disclose
              your personal information, without notice, if required to do so by
              law or in the good faith belief that such action is necessary to:
              (a) conform to the edicts of the law or comply with legal process
              served on TMC or the site; (b) protect and defend the rights or
              property of TMC; and/or (c) act under exigent circumstances to
              protect the personal safety of users of TMC, or the public.
            </p>
            <h2>Tracking User Behavior</h2>
            <p>
              TMC may keep track of the websites and pages our users visit
              within TMC, in order to determine what TMC services are the most
              popular. This data is used to deliver customized content and
              advertising within TMC to customers whose behavior indicates that
              they are interested in a particular subject area.
            </p>
            <h2>Automatically Collected Information</h2>
            <p>
              Information about your computer hardware and software may be
              automatically collected by TMC. This information can include: your
              IP address, browser type, domain names, access times, and
              referring website addresses. This information is used for the
              operation of the service, to maintain the quality of the service,
              and to provide general statistics regarding the use of the TMC
              website.
            </p>
            <h2>Security of your Personal Information</h2>
            <p>
              TMC secures your personal information from unauthorized access,
              use, or disclosure. TMC uses the following methods for this
              purpose:{" "}
            </p>
            <p>
              {" "}
              <strong> - SSL</strong> <br />
            </p>
            <p>
              When personal information (such as a credit card number) is
              transmitted to other websites, it is protected through the use of
              encryption, such as the Secure Sockets Layer (SSL) protocol. We
              strive to take appropriate security measures to protect against
              unauthorized access to or alteration of your personal information.
              Unfortunately, no data transmission over the Internet or any
              wireless network can be guaranteed to be 100% secure. As a result,
              while we strive to protect your personal information, you
              acknowledge that: (a) there are security and privacy limitations
              inherent to the Internet which are beyond our control; and (b)
              security, integrity, and privacy of any and all information and
              data exchanged between you and us through this Site cannot be
              guaranteed.
            </p>
            <h2>Children Under Thirteen</h2>
            <p>
              TMC does not knowingly collect personally identifiable information
              from children under the age of thirteen. If you are under the age
              of thirteen, you must ask your parent or guardian for permission
              to use this website.
            </p>
            <h2>E-mail Communications</h2>
            <p>
              From time to time, TMC may contact you via email for the purpose
              of providing announcements, promotional offers, alerts,
              confirmations, surveys, and/or other general communication. In
              order to improve our Services, we may receive a notification when
              you open an email from TMC or click on a link therein. If you
              would like to stop receiving marketing or promotional
              communications via email from TMC, you may opt-out of such
              communications by clicking on the UNSUBSCRIBE button or replying
              "STOP".
            </p>
            <h2>Changes to this Statement</h2>
            <p>
              TMC reserves the right to change this Privacy Policy from time to
              time. We will notify you about significant changes in the way we
              treat personal information by sending a notice to the primary
              email address specified in your account, by placing a prominent
              notice on our site, and/or by updating any privacy information on
              this page. Your continued use of the Site and/or Services
              available through this Site after such modifications will
              constitute your: (a) acknowledgment of the modified Privacy
              Policy; and (b) agreement to abide and be bound by that Policy.
            </p>
            <h2>Contact Information</h2>
            <p>
              TMC welcomes your questions or comments regarding this Statement
              of Privacy. If you believe that TMC has not adhered to this
              Statement, please contact TMC at the Mars Contract email adddress:
              <br />
              <br />
              <br />
              hello@themarscontract.com
              <br />
              Effective as of April 15, 2021
            </p>
            <div>
              <button
                sx={{
                  borderRadius: 50,
                  border: `none`,
                  width: [`50%`, `50`, `30%`, `25%`, `20%`],
                  p: 2,
                  marginBottom: `2rem`,
                }}
              >
                <Link to="/">
                  <em
                    sx={{
                      fontFamily: `Libre Baskerville`,

                      fontSize: 2,
                    }}
                  >
                    Home
                  </em>
                </Link>
              </button>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};
export default Privacy;
